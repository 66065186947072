<template>
  <div class="wrap sign-page">
    <div class="modal-dim on">
      <!--'on 추가'-->
      <div class="set-window-wrap">
        <h1 class="lx-logo"></h1>
        <h2 class="title-txt"></h2>
        <div class="set-window small sign">
          <div class="set-window-top">
            <div class="set-window-sub">
              <button class="i-back" @click="fnBack"></button>
              <div class="sub">
                <h2>회원가입</h2>
              </div>
            </div>
          </div>
          <div class="set-window-con" style="border: 1px solid #b7cdbf; background:#fafcfb">
            <div class="sect pt0" style="max-height: unset; background:#fafcfb">
              <p class="input-info" style="color: #2c3033">*는 필수 입력 항목입니다.</p>
              <div class="form column">
                <p class="form-sub" style="color: #2c3033">이메일*</p>
                <label class="label">
                  <input type="email" placeholder="E-mail 주소 입력" v-model="form.email" />
                </label>
                <p class="valid on" style="letter-spacing: -0.06em" v-if="valid.email.$error">
                  {{ msg.email }}
                </p>
              </div>
              <div class="form column">
                <p class="form-sub" style="color: #2c3033">이름*</p>
                <label class="label">
                  <input type="text" placeholder="영문 or 한글 입력" v-model="form.name" />
                </label>
                <p class="valid on" style="letter-spacing: -0.06em" v-if="valid.name.$error">
                  {{ msg.name }}
                </p>
              </div>
              <div class="form column">
                <p class="form-sub" style="color: #2c3033">비밀번호*</p>
                <label class="label">
                  <input type="password" placeholder="6자리 이상 영문+숫자" v-model="form.password" />
                </label>
                <p class="valid on" style="letter-spacing: -0.06em" v-if="valid.password.$error">
                  {{ msg.password }}
                </p>
              </div>
              <div class="form column">
                <p class="form-sub" style="color: #2c3033">비밀번호 확인*</p>
                <label class="label">
                  <input type="password" placeholder="6자리 이상 영문+숫자" v-model="form.confirmPw" />
                </label>
                <p class="valid on" style="letter-spacing: -0.06em" v-if="valid.confirmPw.$error">
                  {{ msg.confirmPw }}
                </p>
              </div>
            </div>
            <div class="btn-wrap" style="background:#fafcfb">
              <button class="btn color" style="width: 100%;" @click="fnSignUp">
                완료
              </button>
            </div>
          </div>
        </div>
        <ul class="cust-wrap">
          <li class="cust cust-sync"></li>
          <li class="cust cust-keti"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useModalState } from '@/common/state/ModalState'
import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'

const { withAsync } = helpers

export default {
  name: 'sign-up-index',
  setup() {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert } = useModalState()
    const router = useRouter()

    const state = reactive({
      form: {
        email: '',
        name: '',
        password: '',
        confirmPw: ''
      },
      msg: {
        email: '',
        name: '',
        password: '',
        confirmPw: ''
      }
    })

    const fnSignUp = async () => {
      valid.value.$touch()
      if (valid.value.$invalid) return
      try {
        await proxy.$AuthUtils.register(state.form)
        await fnModalAlert('회원가입', '회원가입에 성공했습니다. 로그인을 해주세요.', '')
      } catch (error) {
        await fnModalAlert('알람', '회원가입에 실패했습니다.', '')
      }
    }
    const fnBack = () => {
      router.push({ name: 'SignIndex' })
    }

    const rules = {
      email: {
        test: withAsync(async val => {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.email = '이메일을 입력하지 않았습니다.'
            return false
          }
          if (!proxy.$Util.isEmailValid(val)) {
            state.msg.email = '이메일 형식이 유효하지 않습니다.'
            return false
          }
          const checkEmail = await proxy.$AuthUtils.checkEmail(val)
          console.log(checkEmail)
          if (!checkEmail.data) {
            state.msg.email = '동일한 이메일이 사용중입니다. 다른 이메일을 입력해주세요.'
            return false
          }
          return true
        })
      },
      name: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.name = '성함을 입력하지 않았습니다.'
            return false
          }
          if (!proxy.$Util.isNameValid(val)) {
            state.msg.name = '한글 또는 영문으로 입력해주세요.'
            return false
          }
          return true
        }
      },
      password: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.password = '사용할 비밀번호를 설정해주세요.'
            return false
          }
          if (!proxy.$Util.isPasswordValid(val)) {
            state.msg.password = '입력하신 비밀번호가 유효하지 않습니다. 6자리 이상의 영문+숫지 조합으로 변경해주세요.'
            return false
          }
          return true
        }
      },
      confirmPw: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.confirmPw = '비밀번호가 확인되지 않았습니다.'
            return false
          }
          if (!proxy.$Util.isPasswordValid(val)) {
            state.msg.confirmPw = '입력하신 비밀번호가 유효하지 않습니다. 6자리 이상의 영문+숫지 조힙으로 변경해주세요.'
            return false
          }
          if (state.form.password !== val) {
            state.msg.confirmPw = '비밀번호가 일치하지 않습니다. 확인 후, 입력해주세요.'
            return false
          }
          return true
        }
      }
    }
    const valid = useVuelidate(rules, state.form)

    return {
      ...toRefs(state),
      fnSignUp,
      fnBack,
      valid
    }
  }
}
</script>

<style scoped></style>
